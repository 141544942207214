<template>
  <div class="mine">
    <div class="container">
      <Menu :active="3"/>
      <div class="main">
        <Header @getContract="getContract"/>
        <div class="welcome">
          <span>Check,</span> My Details
        </div>
        <div class="address bWrap">
          <div class="title">
            <img src="@/assets/imgs/icon_mine.png">
            <div class="text">Recipient Details</div>
          </div>
          <div v-if="addressList.length" class="list">
            <div v-for="(item,index) in addressList" :key="index" class="item">
              <div class="wrap">
                <div class="flex">
                  <div class="name">{{ item.u_name }}</div>
                  <div class="edit" @click="openEdit(item.id)">Edit</div>
                </div>
                <div class="row">
                  <span>Mobile :</span>{{ item.mobile }}
                </div>
                <div class="row">
                  <span>Receiver Email :</span>{{ item.login_email }}
                </div>
                <div class="row">
                  <span>Contact Email :</span>{{ item.email }}
                </div>
                <div class="row">
                  <span>Wallet Address :</span>{{ item.receiving_address }}
                </div>
                <div class="row">
                  <span>Address :</span>
                  {{ item.country_name + ' ' + item.area_line_1 + ' ' + item.area_line_2 + ' ' + item.city + ' ' + item.province }}
                </div>
              </div>
              <div class="line"></div>
            </div>
          </div>
          <div v-else class="noData">No data available</div>
          <div class="add" @click="openAdd">
            <img src="@/assets/imgs/icon_add1.png">
            <div class="text">Add New Address</div>
          </div>
        </div>
      </div>
    </div>

    <!--添加/修改地址-->
    <AddAddress ref="add" :contract="contract" @added="getData"/>
  </div>
</template>

<script setup>
  import AddAddress from "./AddAddress"
  import { ref } from  'vue'
  import { getUserAddress } from '@/request/api'

  const addressList = ref([])
  const contract = ref({})
  let address = ''
  function getContract(obj){
    contract.value = obj
    address = obj.address
    getData()
  }
  function getData(){
    getUserAddress({
      address
    }).then(res => {
      if(res.success) {
        addressList.value = res.data
      }
    })
  }
  const add = ref(null)
  function openAdd() {
    add.value.visible = true
    add.value.getAreaCode()
    add.value.getCountries()
  }

  function openEdit(id) {
    add.value.visible = true
    add.value.getAreaCode()
    add.value.getCountries()
    add.value.aId = id
  }
</script>

<style lang="less" scoped>
.mine{
  min-height: 100vh;
  background: linear-gradient(180deg, #1B1B1B 53.13%, #0D0D0D 100%);

  .container {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .main {
      width: 888px;
      padding-top: 20px;

      .welcome{
        font-size: 33px;
        margin: 54px 0 27px 0;

        span{
          color: #697377;
        }
      }
      .address{
        min-height: calc(100vh - 210px);
        padding-bottom: 20px;

        .title{
          display: flex;
          align-items: center;
          font-size: 17px;
          padding: 20px 0 0 20px;
          margin-bottom: 26px;

          img{
            width: 21px;
            margin-right: 16px;
          }
        }
        .list{
          .item{
            margin-bottom: 16px;

            .wrap{
              padding:0 35px 14px 58px;

              .flex{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 17px;
                margin-bottom: 10px;

                .name{
                  color: #06C8A1;
                }
                .edit{
                  color: #33F88F;
                  cursor: pointer;
                }
              }
              .row{
                font-size: 15px;
                color: #697377;
                line-height: 26px;

                span{
                  color: #ffffff;
                  width: 142px;
                  display: inline-block;
                }
              }
            }
            .line{
              height: 0.7px;
              border-radius: 12px;
              background: radial-gradient(50% 50% at 50% 50.00%, #949494 78.65%, #000 100%);
            }
          }
          .item:last-child{
            margin-bottom: 0;
          }
        }
        .noData{
          text-align: center;
          margin: 50px 0;
        }
        .add{
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 24px;
          cursor: pointer;

          img{
            width: 28px;
            margin-right: 13px;
          }
          .text{
            font-size: 17px;
          }
        }
      }
    }
  }
}
</style>
