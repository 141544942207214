<template>
  <div ref="add" class="modal">
    <a-modal
        v-model:visible="visible"
        centered
        width="500px"
        :footer="null"
        :closable="false"
        :get-container="() => $refs.add"
        @cancel="cancel"
    >
      <div class="add">
        <div class="mWrap">
          <div class="title">Fill In Your Details</div>
          <div class="line"></div>
          <div class="form">
            <div v-show="isFirst">
              <div class="item green">
                <div class="label">Receiver</div>
                <input type="text" v-model="form.u_name">
              </div>
              <div class="item">
                <div class="label">International Mobile Number</div>
                <div class="select">
                  <a-select
                      ref="select"
                      v-model:value="areaCode"
                      show-search
                      size="small"
                      :bordered="false"
                      :filter-option="filterCode"
                      style="width: 120px"
                      :options="codeList"
                      :field-names="{ label: 'code', value: 'code'}"
                  ></a-select>
                  <input v-model="phone">
                </div>
              </div>
              <div class="item">
                <div class="label">
                  <div class="text">Login E-mail</div>
                  <a-tooltip placement="right" color="#3b3b3a">
                    <template #title>For log in to the product, your login credentials will be sent to the email address.</template>
                    <img src="@/assets/imgs/icon_tips.png">
                  </a-tooltip>
                </div>
                <input type="text" v-model="form.login_email">
              </div>
              <div class="item">
                <div class="label">
                  <div class="text">Contact E-mail</div>
                  <a-tooltip placement="right" color="#3b3b3a">
                    <template #title>If you only have one email address, you can use it for all contact purposes.</template>
                    <img src="@/assets/imgs/icon_tips.png">
                  </a-tooltip>
                </div>
                <input type="text" v-model="form.email">
              </div>
              <div class="item">
                <div class="label">Wallet Address</div>
                <input type="text" v-model="form.receiving_address">
              </div>
            </div>
            <div v-show="!isFirst">
              <div class="flex">
                <div class="item">
                  <div class="label">Country</div>
                  <a-select
                      ref="select"
                      v-model:value="form.country_id"
                      show-search
                      size="small"
                      :bordered="false"
                      :filter-option="filterOption"
                      style="width: 100%"
                      :options="countries"
                      :field-names="{ label: 'country_name', value: 'country_id'}"
                  ></a-select>
                </div>
                <div class="item">
                  <div class="label">City</div>
                  <input type="text" v-model="form.city">
                </div>
              </div>
              <div class="flex">
                <div class="item">
                  <div class="label">State/ Province/ Region</div>
                  <input type="text" v-model="form.province">
                </div>
                <div class="item">
                  <div class="label">Zip/ Postal Code</div>
                  <input type="text" v-model="form.post_code">
                </div>
              </div>
              <div class="item">
                <div class="label">Address Line 1</div>
                <input type="text" v-model="form.area_line_1">
              </div>
              <div class="item">
                <div class="label">Address Line 2</div>
                <input type="text" v-model="form.area_line_2">
              </div>
            </div>
          </div>
        </div>
        <div class="btnWrap">
          <div class="btn" @click="cancel">Cancel</div>
          <div class="btn confirm" @click="handleSubmit">{{ isFirst ? 'Next' : 'Confirm'}}</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue'
  import { countryList, getCountryCode, addUserAddress } from '@/request/api'
  import { message } from 'ant-design-vue'

  const aId = ref(undefined)
  const visible = ref(false)
  const isFirst = ref(true)

  const props = defineProps(['contract'])

  defineExpose({ visible, aId, getAreaCode, getCountries })

  const emits = defineEmits(['added'])

  const areaCode = ref('')
  const phone = ref('')
  const form = reactive({
    u_name: '',
    mobile: '',
    login_email: '',
    email: '',
    receiving_address: '',
    country_id: '',
    city: '',
    province: '',
    post_code: '',
    area_line_1: '',
    area_line_2: ''
  })

  function handleSubmit() {
    if(isFirst.value) {
      if(form.u_name == '') {
        message.warning('Please Enter Receiver')
        return
      }
      if(areaCode.value == '') {
        message.warning('Please select an international area code')
        return
      }
      if(phone.value == '') {
        message.warning('Please Enter Mobile Number')
        return
      }
      if(form.login_email == '') {
        message.warning('Please Enter Login E-mail')
        return
      }
      if(form.email == '') {
        message.warning('Please Enter Contact E-mail')
        return
      }
      if(form.receiving_address == '') {
        message.warning('Please Enter Receiving Product Wallet Address')
        return
      }
      isFirst.value = false
    } else {
      if(form.country_id == '') {
        message.warning('Please select a country')
        return
      }
      if(form.city == '') {
        message.warning('Please Enter City')
        return
      }
      if(form.province == '') {
        message.warning('Please Enter Province')
        return
      }
      if(form.post_code == '') {
        message.warning('Please Enter Postal Code')
        return
      }
      if(form.area_line_1 == '') {
        message.warning('Please Enter Address Line 1')
        return
      }
      if(form.area_line_2 == '') {
        message.warning('Please Enter Address Line 2')
        return
      }
      form.address = sessionStorage.getItem('address')
      form.user_address_id = aId.value
      form.mobile = areaCode.value + ' ' + phone.value
      let zuDisable = false
      addUserAddress(form).then(async res => {
        if(res.success) {
          if(zuDisable) {
            return
          }
          zuDisable = true
          const { gasPrice, conContract } = props.contract
          try {
            const res1 = await conContract.AddEditShippingAddress(res.data, {
              gasPrice,
              gasLimit: 200000
            })
            if(res1) {
              zuDisable = false
              setTimeout(() => {
                message.success('Operation Successful')
                emits('added')
                cancel()
              }, 1000)
            }
          } catch (e) {
            zuDisable = false
            message.error('Operation Failed')
          }
        } else {
          message.warning(data.msg)
        }
      })
    }
  }

  const codeList = ref([])
  function getAreaCode() {
    getCountryCode().then(res => {
      if(res.success) {
        codeList.value = res.data
      }
    })
  }

  const countries = ref([])
  function getCountries() {
    countryList().then(res => {
      if(res.success) {
        countries.value = res.data
      }
    })
  }

  function filterOption(input, option) {
    return option.country_name.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  function filterCode(input, option) {
    return option.code.indexOf(input) >= 0
  }

  function cancel(){
    visible.value = false
    form.u_name = ''
    form.mobile = ''
    areaCode.value = ''
    phone.value = ''
    form.login_email = ''
    form.email = ''
    form.receiving_address = ''
    form.country_id = ''
    form.city = ''
    form.province = ''
    form.post_code = ''
    form.area_line_1 = ''
    form.area_line_2 = ''
    aId.value = undefined
    isFirst.value = true
  }
</script>

<style lang="less" scoped>
.modal{
  ::v-deep .ant-modal-content{
    background: transparent;
    box-shadow: none;

    .ant-modal-body{
      padding: 0;
    }
  }
  ::v-deep .ant-modal-mask{
    background: rgba(0, 0, 0, 0.8);
  }
  .add{
    color: #ffffff;

    .mWrap {
      height: 500px;
      margin-bottom: 24px;
      padding: 26px 0;

      .title{
        font-size: 22px;
        text-align: center;
        line-height: 20px;
        margin-bottom: 23px;
      }
      .line{
        margin: 0 16px 36px 16px;
        border-radius: 12px;
        height: 2px;
        background: radial-gradient(50% 50% at 50% 50.00%, #949494 78.65%, #000 100%);
      }
      .form{
        padding: 0 30px;

        .flex{
          display: flex;
          justify-content: space-between;

          .item{
            width: calc(50% - 10px);
          }
        }
        .item{
          height: 44px;
          border-radius: 7px;
          border: 1.5px solid #FFF;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.10) 100%);
          margin-bottom: 35px;
          position: relative;
          padding: 10px 12px 0 12px;

          .label{
            font-size: 14px;
            color: #697377;
            position: absolute;
            left: 9px;
            top: -9px;
            line-height: 1;
            padding: 0 10px 0 4px;
            background: #000000;
            display: flex;
            align-items: center;

            img{
              width: 8px;
              margin-left: 8px;
            }
          }
          input{
            width: 100%;
            background: transparent;
            font-size: 15px;
          }
          .select{
            display: flex;
            align-items: center;
          }
        }
        .green{
          border-color: #06C8A1;

          .label{
            color: #06C8A1;
          }
        }
      }
    }
    .btnWrap{
      display: flex;
      justify-content: center;

      .btn{
        width: 112px;
        height: 44px;
        font-size: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #697377;
        border-radius: 18px;
        border: 1.5px solid #ffffff;
        color: #ffffff;
        cursor: pointer;
      }
      .confirm{
        margin-left: 60px;
        border: none;
        background: #06C8A1;
      }
    }
  }
}
</style>
